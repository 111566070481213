import React from 'react';
import '../App.css';

function Privacy() {
  return (
    <div className="privacy-container">
      <h1 className="privacy-title">Privacy Policy</h1>
      <div className="privacy-content">
        
        <div className="privacy-section">
          <h2>1. Data Protection Commitment</h2>
          <p>Propertunity is committed to protecting your privacy. We do not sell, trade, or share your personal data with third parties under any circumstances, except with trusted partners who facilitate our operations, such as payment processing (e.g., Stripe).</p>
        </div>

        <div className="privacy-section">
          <h2>2. Data Collection & Usage</h2>
          <p>We collect only the necessary data to provide our services. We do not use Google Analytics or other third-party tracking services; instead, we rely on our own native analytics to maintain user privacy.</p>
        </div>

        <div className="privacy-section">
          <h2>3. Legal Basis for Processing</h2>
          <p>We process personal data based on:</p>
          <ul>
            <li>Contractual necessity (e.g., processing payments)</li>
            <li>Legitimate interest (e.g., service improvement)</li>
            <li>Legal obligations (e.g., compliance with financial regulations)</li>
          </ul>
        </div>

        <div className="privacy-section">
          <h2>4. Data Subject Rights (GDPR)</h2>
          <p>As per the General Data Protection Regulation (GDPR), users in the EU have the right to:</p>
          <ul>
            <li>Access their personal data</li>
            <li>Request correction or deletion of their data</li>
            <li>Object to processing in certain cases</li>
            <li>Request data portability</li>
          </ul>
          <p>To exercise these rights, please contact us at <a href="mailto:support@propertunityltd.com">support@propertunityltd.com</a>.</p>
        </div>

        <div className="privacy-section">
          <h2>5. Data Security</h2>
          <p>We implement strong security measures to protect your information. Data is encrypted and stored securely, and we only retain it for as long as necessary.</p>
        </div>

        <div className="privacy-section">
          <h2>6. Third-Party Services</h2>
          <p>We share data only with service providers essential for our operations (e.g., Stripe for payments). These partners are GDPR-compliant and process data solely on our behalf.</p>
        </div>

        <div className="privacy-section">
          <h2>7. Updates to This Policy</h2>
          <p>We may update this policy periodically. Any significant changes will be communicated to users.</p>
        </div>

        <p className="privacy-footer">For questions or data requests, contact us at <a href="mailto:support@propertunityltd.com">support@propertunityltd.com</a>.</p>
      </div>
    </div>
  );
}

export default Privacy;
