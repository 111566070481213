import React, { useState, useEffect } from 'react';
import './App.css';
import Navbar from './components/Navbar';
import Footer from './components/Footer';
import HomePage from './components/Index'; 
import AboutUs from './components/AboutUs';
import OurPeople from './components/OurPeople';
import ContactUs from './components/ContactUs';
import Equity from './components/Equity';
import Bonds from './components/Bonds';
import Investments from './components/Investments';
import Community from './components/Community';
import Owners from './components/Owners';
import Compliance from './components/Compliance';
import Slide4 from './components/Slide4';
import Slide2 from './components/Slide2';
import Slide3 from './components/Slide3';
import Privacy from './components/Privacy';


import { BrowserRouter as Router, Routes, Route, useLocation } from 'react-router-dom';
import { motion, AnimatePresence } from 'framer-motion';

function MainContent() {
  const [currentSlide, setCurrentSlide] = useState(0);
  const location = useLocation();

  const slides = [
    { id: 0, component: <HomePage /> },
    { id: 1, component: <Slide2 /> },
    { id: 2, component: <Slide3 /> },
    { id: 3, component: <Slide4 /> },
  ];



  useEffect(() => {
    const handleKeyDown = (event) => {
      if (location.pathname !== '/') return;
      
      if (event.key === 'ArrowRight') {
        setCurrentSlide((prev) => (prev + 1) % slides.length);
      } else if (event.key === 'ArrowLeft') {
        setCurrentSlide((prev) => (prev - 1 + slides.length) % slides.length);
      }
    };
    window.addEventListener('keydown', handleKeyDown);
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [ location.pathname, slides.length]);

  // const AboutSection = () => (
  //   <div className="about-section">
  //     <div className="about-content">
  //       <h2>More about us!!</h2>
  //       <p>Your additional content can go here...</p>
  //     </div>
  //   </div>
  // );

  return (
    <div className="main-container">
      <div className="App" style={{ position: 'relative', overflow: 'hidden' }}>
        <AnimatePresence mode="wait">
          {location.pathname === '/' ? (
            <>
              <motion.div
                key="slides"
                className="slides-container"
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                transition={{ duration: 0.5 }}
              >
                {slides[currentSlide].component}
                
              </motion.div>
              <motion.div
                key="about-section"
                className="static-content"
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ delay: 0.5 }}
              >
                {/* <AboutSection /> */}
              </motion.div>
            </>
          ) : (
            <motion.div
              key={location.pathname}
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              transition={{ duration: 0.5 }}
            >
              <Routes>
                <Route path="/about-us" element={<AboutUs />} />
                <Route path="/our-people" element={<OurPeople />} />
                <Route path="/contact-us" element={<ContactUs />} />
                <Route path="/equity" element={<Equity />} />
                <Route path="/bonds" element={<Bonds />} />
                <Route path="/investments" element={<Investments />} />
                <Route path="/community" element={<Community />} />
                <Route path="/owners" element={<Owners />} />
                <Route path="/compliance" element={<Compliance />} />
                <Route path="/privacy" element={<Privacy />} />
                
              </Routes>
            </motion.div>
          )}
        </AnimatePresence>
      </div>
    </div>
  );
}

function App() {
  return (
    <Router>
      <div className="content-wrapper">
        <Navbar />
        <MainContent />
        <Footer />
      </div>
    </Router>
  );
}

export default App;