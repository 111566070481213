import React from 'react';
import { Link } from 'react-router-dom';
import '../App.css';

function Footer() {
  const currentYear = new Date().getFullYear();

  return (
    <footer className="footer">
      <p>© Propertunity {currentYear}</p>
      <p>
        <Link to="/privacy" className="footer-link">Privacy</Link>
      </p>
    </footer>
  );
}

export default Footer;
